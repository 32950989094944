<template>
  <div>
    <div class="wrapper">
      <!-- section prices -->
      <section
        id="login"
        class="section jpadding jpadding-20 section-unfixed"
        style="background: #1193f5"
      >
        <!-- 
            <div class="illustration">
                <img src="/img/vectors/talkii-illustration2.svg" alt="">
            </div> -->
        <div class="mb-3 jcard jcard-main jcard-register jcard-nohover">
          <p>Talkii {{ title }}</p>
          <p>{{ PLAN.USERS }} user(s)</p>
          <p></p>
          <p>
            Total <strong>{{ PLAN.PRICE }}€</strong>
          </p>
        </div>

        <div class="jcard jcard-main jcard-register jcard-nohover">
          <div class="brand mb-3">
            <img
              src="@/assets/logos/talkii-logo-gradient.png"
              alt="Talkii Logo"
            />
          </div>
          <div class="sub-title text-center">
            <h2>Checkout</h2>
          </div>

          <div class="login">
            <div class="form-row">
              <div class="col">
                <div class="form-group">
                  <label for="email">
                    <i class="fa fa-user"></i> First Name
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="userData.firstName"
                  />
                </div>
              </div>

              <div class="col">
                <div class="form-group">
                  <label for="email"
                    ><i class="fa fa-user"></i> Last Name</label
                  >
                  <input
                    type="text"
                    class="form-control"
                    v-model="userData.lastName"
                  />
                </div>
              </div>
            </div>

            <div class="form-group">
              <label for="email"
                ><i class="fa fa-map-marker"></i> Address</label
              >
              <input
                type="text"
                class="form-control"
                v-model="userData.address"
              />
            </div>

            <div class="form-row">
              <div class="col">
                <div class="form-group">
                  <label for="email"
                    ><i class="fa fa-map-marker"></i> Zip</label
                  >
                  <input
                    type="text"
                    class="form-control"
                    v-model="userData.zip"
                  />
                </div>
              </div>

              <div class="col">
                <div class="form-group">
                  <div class="form-group">
                    <label for="email"
                      ><i class="fa fa-phone-alt"></i> Phone</label
                    >
                    <input
                      type="text"
                      class="form-control"
                      v-model="userData.phone"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div class="form-group">
              <label for="email"
                ><i class="fa fa-globe-europe"></i> Country</label
              >
              <b-form-select
                v-model="userData.country"
                :options="countriesList"
              ></b-form-select>
            </div>

            <div class="form-group">
              <label for="email"><i class="fa fa-user"></i> Email</label>
              <input
                type="text"
                class="form-control"
                v-model="userData.email"
              />
              <div class="invalid-feedback">
                Please provide a valid city.
              </div>
            </div>

            <div class="form-group">
              <label for="password"><i class="fa fa-lock"></i> Password</label>
              <input
                type="password"
                class="form-control"
                v-model="userData.password"
              />
            </div>

            <div class="form-group">
              <label for="password"
                ><i class="fa fa-lock"></i> Repeat password</label
              >
              <input
                type="password"
                class="form-control"
                v-model="userData.passwordCheck"
              />
            </div>

            <div ref="paypal"></div>

            <div class="btn-container text-center mt-4 mb-4">
              <button class="jbtn jbtn-blue" value="Add" @click="registerUser">
                Singup!
              </button>
                <button class="jbtn jbtn-blue" value="Add" @click="addSubscription">
                Add subscription
              </button>
            </div>

            <div class="login-message">
              <p>{{ validationMsg }}</p>
              <p>{{ pwNotOkMsg }}</p>
            </div>

            <div class="bottom-links text-center">
              <router-link to="/login"
                ><p><a>Already have an account?</a></p></router-link
              >
            </div>
          </div>
        </div>
      </section>
    </div>
    <section class="separator separator-lux jpadding">
      <img src="@/assets/logos/made-in-lux.svg" alt="Made in Luxembourg" />
    </section>
  </div>
</template>

<script>
import firebase from "firebase/app";
import db from "../firebase/firebaseInit";
import axios from "axios";
import C from "@/constants";
import PLANS from "@/plans";

export default {
  name: "Checkout",
  data() {
    return {
      //global
      title: PLANS.ALL[0].NAME + " " + PLANS.BILLED_INDEX[PLANS.ALL[0].BILLED],
      planId: 0,
      PLAN: PLANS.ALL[0],

      //form input data
      userData: {
        firstName: "",
        lastName: "",
        address: "",
        zip: "",
        country: "Luxembourg",
        phone: "",
        email: "",
        password: "",
        passwordCheck: "",
      },
      //firebase document
      userDoc: {
        ready: false,
        admin: false,
        premium: false,

        aacType: 2, // platform_account
        status: 5, // expired
        token: null, // ignore
        sku: null, // subscription type
        expiry: Math.floor(Date.now()), // in ms

        ic: "",
        name: "",
        email: "",
        addr: "",
        zip: "",
        country: "",
        phone: "",

        children: new Array(),
        chName: new Array(),
        chColor: new Array(),
        chLang: new Array(),
        chLayout: new Array(),
        chColumn: new Array(),
        chShowTitle: new Array(),
        chAiVoice: new Array(),
        chTlWeekMode: new Array(),
        chShowTime: new Array(),
        cTlCount: new Array(),
        eTlLastAcc: new Array(),
        active: -1,
        tlColors: [
          "#d3d3d3",
          "#d3d3d3",
          "#d3d3d3",
          "#d3d3d3",
          "#d3d3d3",
          "#d3d3d3",
          "#d3d3d3",
        ],
        eTlMode: false,
        upV: Math.floor(Date.now()),
      },

      countriesList: C.COUNTRIES,
      validEmail: true,
      validFirstName: true,
      validLastName: true,
      validPassword: true,

      validationMsg: "",
      pwNotOkMsg: "",

      //paypal
      loaded: false,
      paidFor: false,
      product: {
        price: 33.33,
        description: "Talkii Package",
        img: "@/assets/talkii-logo-gradient.png",
      },
      //subscription
      subscription: {
        userId: firebase.auth().currentUser.uid, //from firebase
        aacType: 0, //subscription type, from plan
        planId: 0, //sku, from plan
        planName: "", //subscription name, from plan
        start: Date.now, //from paypal
        expiry: Date.now, //from paypal
        duration: 0, //from plan
        price: 0, //from plan
        orderId: "NULL", //from paypal
        isPaid: false, //from paypal
        deliveryId: "NULL",
        isDelivered: false,
        status: PLANS.STATUS.FAILED, //from paypal
        //description: "",
      },
    };
  },
  mounted: function() {
    //selected plan
    console.log("Selected Plan: " + this.$route.query.buy);
    console.log(this.PLAN);
    if (!this.$route.query.buy) {
      console.log("No plan selected via query. Default plan (0) was loaded.");
    } else {
      //init global data from plan
      this.title =
        PLANS.ALL[this.$route.query.buy].NAME +
        " " +
        PLANS.BILLED_INDEX[PLANS.ALL[this.$route.query.buy].BILLED];
      this.planId = this.$route.query.buy;
      this.PLAN = PLANS.ALL[this.$route.query.buy];
      //init subscription data from plan
      this.subscription.aacType = this.PLAN.TYPE;
      this.subscription.planName = this.PLAN.NAME;
      this.subscription.planId = this.planId;
      this.subscription.duration = this.PLAN.DURATION;
      this.subscription.price = this.PLAN.PRICE;
      console.log('Subscription price: '+this.subscription.price);
    }
    //load PayPal sdk
    const script = document.createElement("script");
    script.src =
      "https://www.paypal.com/sdk/js?client-id=ASXh5-TD9MjMuDZmmoMcyUGlkaxDIs5GdFZjBEo0OH_CP2K-TPhb4hR1pkHL-s6gJxvJwj_X6oYBLqDF&currency=EUR";
    script.addEventListener("load", this.setLoaded);
    document.body.appendChild(script);
  },
  methods: {
    //called when PayPal sdk is loaded
    setLoaded: function() {
      this.loaded = true;
      window.paypal
        .Buttons({
          createOrder: (data, actions) => {
            return actions.order.create({
              purchase_units: [
                {
                  description: this.product.description,
                  amount: {
                    currency_code: "EUR",
                    value: this.subscription.price,
                  },
                },
              ],
            });
          },
          onApprove: async (data, actions) => {
            console.log("onApprove fires");
            //this.registerUser();
            const order = await actions.order.capture();
            this.data;
            this.paidFor = true;
            console.log(order);
            //set subscription data from paypal order data
            this.subscription.start = new Date(order.create_time);
            this.subscription.expiry = new Date(order.create_time);
            this.subscription.expiry.setMonth(
              this.subscription.start.getMonth() + this.PLAN.DURATION
            );
            this.subscription.orderId = order.id;
            this.subscription.isPaid = true;
            this.subscription.status = PLANS.STATUS.ACTIVE;
            console.log(this.subscription);
            this.addSubscription();
          },
          onError: (err) => {
            console.log(err);
            this.subscription.status = "failed";
          },
        })
        .render(this.$refs.paypal);
    },
    registerUser() {
      // TODO: MORE INPUT VALIDATION

      if (this.validate()) {
        this.validationMsg = "SUCCESS";
        firebase
          .auth()
          .createUserWithEmailAndPassword(
            //create auth user
            this.userData.email,
            this.userData.password
          )
          .then(() => {
            this.subscription.userId = firebase.auth().currentUser.uid;
            //update auth user
            firebase
              .auth()
              .currentUser.updateProfile({
                displayName:
                  this.userData.firstName + " " + this.userData.lastName,
              })
              .then(() => {
                //create user doc
                this.validationMsg =
                  "Welcome " + firebase.auth().currentUser.displayName;
                db.collection("users")
                  .doc(firebase.auth().currentUser.uid)
                  .set({
                    firstname: this.userData.firstName,
                    lastname: this.userData.lastName,
                    email: this.userData.email,
                  })
                  .then(() => {
                    this.pwNotOkMsg = "";
                    // this.$router.replace("/");
                    console.log("Firebase userDoc created");
                  })
                  .catch((err) => {
                    this.pwNotOkMsg = "COULD NOT CREATE DOCUMENT";
                    this.validationMsg = err.message;
                  });
              })
              .catch((err) => {
                this.pwNotOkMsg = "COULD NOT UPDATE PROFILE";
                this.validationMsg = err.message;
              });
          })
          .catch((err) => {
            this.validationMsg = err.message;
            this.pwNotOkMsg = "COULD NOT CREATE USER";
          });
        this.pwNotOkMsg = "registration in progress";
      } else {
        this.validationMsg = "Please complete the form";
      }
    },
    addSubscription() {
      //   if (
      //     this.userId != "" &&
      //     this.aacType != "" &&
      //     this.expiry != "" &&
      //     this.orderId != ""
      //   ) {
      axios
        .post("/api/functions.php", {
          request: 2,
          userId: this.subscription.userId,
          aacType: this.subscription.aacType,
          planName: this.subscription.planName,
          planId: this.subscription.planId,
          start: this.subscription.start,
          expiry: this.subscription.expiry,
          duration: this.subscription.duration,
          price: this.subscription.price,
          orderId: this.subscription.orderId,
          isPaid: this.subscription.isPaid,
          deliveryId: this.subscription.deliveryId,
          isDelivered: this.subscription.isDelivered,
          status: this.subscription.status
        })
        .then(function(response) {
          // Fetch records
          //app.allRecords();

          // Empty values
          //app.orderId = "";
          //alert(response.data);
          console.log(response.data);
        })
        .catch(function(error) {
          console.log(error);
        });
      //   } else {
      //     alert("Fill all fields.");
      //   }
    },
    getPlan() {
      axios
        .post("/api/functions.php", {
          request: 1,
        })
        .then(function(response) {
          this.PLAN = response.data;
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    validate() {
      //TODO: use array so we see all the problems at once

      //Check first name
      if (this.userData.firstName.length < 2) {
        this.validFirstName = false;
        return false;
      }
      this.validFirstName = true;
      //Check last name
      if (this.userData.lastName.length < 2) {
        this.validLastName = false;
        return false;
      }
      this.validLastName = true;
      //Check email
      if (!this.checkEmail(this.userData.email)) {
        this.validEmail = false;
        return false;
      }
      this.validEmail = true;
      //Check password
      if (this.userData.password.length < 8) {
        this.validPassword = false;
        this.pwNotOkMsg = "Your password is too short (8 characters min.)";
        return false;
      }
      this.pwNotOkMsg = "";
      //Check confirm password
      if (this.userData.password != this.userData.passwordCheck) {
        this.validPassword = false;
        this.pwNotOkMsg = "Your passwords did not match";
        return false;
      }
      this.pwNotOkMsg = "";
      this.validPassword = true;
      return true;
    },
    checkEmail: function(email) {
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    },
  },
  created: {},
};
</script>

<style lang="scss" scoped></style>
